import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React from 'react'
import Flex from '../../Components/Common/Flex'
import { Text } from '../../Components/Common/Text'

export const ModalContent = ({ selectedPayCheck }) => (
  <Flex fw>
    <Text bold value={selectedPayCheck.month} title size={22} />
    <DataTable
      style={{ width: '100%', marginTop: 20 }}
      value={selectedPayCheck.paychecks || []}
      paginator
      rows={10}
      responsive
      autoLayout
      resizableColumns
      emptyMessage='Nessun lavoratore da visualizzare'
    >
      <Column
        field='date' header='Data Invio'
        body={({ date }) => new Date(date).toLocaleString()}
        style={{ border: 'none', textAlign: 'center' }}
      />
      <Column
        field='date' header='Email Inviata con successo'
        body={({ error }) => error ? 'No' : 'Sì'}
        style={{ border: 'none', textAlign: 'center' }}
      />
      <Column field='codiceFiscale' header='Codice Fiscale' style={{ border: 'none', textAlign: 'center' }} />
      <Column field='name' header='Nome' style={{ border: 'none', textAlign: 'center' }} />
      <Column field='page' header='Pagina PDF inviata' style={{ border: 'none', textAlign: 'center' }} />
    </DataTable>
  </Flex>
)

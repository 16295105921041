import React from 'react'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Button } from 'primereact/button'
import { confirmDialog } from 'primereact/confirmdialog'
import { FC } from '../../Services/Client'

const DataTableList = ({ list, style, queryClient }) => {
  const renderDeleteButton = (rowData) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginTop: 0,
        paddingRight: 2,
        width: '100%'
      }}
    >
      <Button
        label='Elimina'
        icon='pi pi-trash'
        onClick={async () => {
          confirmDialog({
            message: `Vuoi cancellare ${rowData.nome} ${rowData.cognome} ?`,
            header: 'Cancellazione',
            icon: 'pi pi-exclamation-triangle',
            accept: async () => {
              try {
                await FC.service('employees').remove(rowData._id, { headers: { Authorization: `${window.localStorage.getItem('jwt')}` } })
                queryClient.invalidateQueries('employees')
              } catch (err) {
                console.log(err)
              }
            },
            reject: () => { },
            acceptLabel: 'Sì'
          })
        }}

      />
    </div>
  )

  return (
    <>

      <DataTable
        style={style}
        value={list}
        paginator
        rows={10}
        responsive
        autoLayout
        resizableColumns
        emptyMessage='Nessun lavoratore da visualizzare'
      >
        <Column
          field='codiceFiscale'
          header='Codice Fiscale'
          filterPlaceholder='Ricerca codice fiscale'
          sortable
          filter
          filterMatchMode='contains'
          style={{ border: 'none', textAlign: 'center' }}
        />
        <Column
          field='email'
          header='Email'
          filterPlaceholder='Ricerca email'
          sortable
          filter
          filterMatchMode='contains'
          style={{ border: 'none', textAlign: 'center' }}
        />
        <Column
          field='nome'
          header='Nome'
          filterPlaceholder='Ricerca nome'
          sortable
          filter
          filterMatchMode='contains'
          style={{ border: 'none', textAlign: 'center' }}
        />
        <Column
          field='cognome'
          header='Cognome'
          filterPlaceholder='Ricerca cognome'
          sortable
          filter
          filterMatchMode='contains'
          style={{ border: 'none', textAlign: 'center' }}
        />
        <Column
          body={renderDeleteButton}
          style={{
            border: 'none',
            width: '150px',
            height: '15px',
            textAlign: 'center'
          }}
        />
      </DataTable>
    </>
  )
}

export default DataTableList

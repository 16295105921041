import { Button } from 'primereact/button'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Flex from '../../Components/Common/Flex'
import { Page } from '../../Components/Common/Page'
import { Modal } from '../../Components/Common/Modal'
import { FC } from '../../Services/Client'
import { ModalContent } from './ModalContent'

export const SentPayChecks = () => {
  const navigate = useNavigate()
  const refModal = useRef(null)
  const [sentPaychecks, setSentPaychecks] = useState([])
  const [selectedPayCheck, setSelectedPayCheck] = useState()

  useEffect(() => {
    const fetchPaychecks = async () => {
      const data = await FC.service('info').get('getSentPaychecks', { headers: { Authorization: `${window.localStorage.getItem('jwt')}` } })
      setSentPaychecks(data)
    }
    fetchPaychecks()
  }, [])

  return (
    <Page>
      <Modal
        ref={refModal} content={<ModalContent selectedPayCheck={selectedPayCheck} />}
      />
      <Flex fw>
        <Flex fw ae style={{ marginBottom: 20 }}>
          <Button label='Torna nella Home' onClick={() => navigate('/')} />
        </Flex>
        <DataTable
          style={{ width: '100%' }}
          value={sentPaychecks}
          paginator
          rows={10}
          responsive
          autoLayout
          resizableColumns
          emptyMessage='Nessun lavoratore da visualizzare'
        >
          <Column
            field='date' header='Data Invio'
            body={({ date }) => new Date(date).toLocaleString()}
            style={{ border: 'none', textAlign: 'center' }}
          />
          <Column
            field='month' header='Mese Buste Inviate'
            style={{ border: 'none', textAlign: 'center' }}
          />
          <Column
            header='Numero buste inviate'
            body={({ paychecks = [] }) => paychecks.length}
            style={{ border: 'none', textAlign: 'center' }}
          />
          <Column
            body={({ paychecks = [], ...row }) => <Button label='Visualizza Dettagli' onClick={() => { refModal.current.show(); setSelectedPayCheck({ ...row, paychecks }) }} />}
            style={{ border: 'none', textAlign: 'center' }}
          />

        </DataTable>
      </Flex>
    </Page>
  )
}


import React from 'react'
import { Text } from './Text'

function Card ({ title, style, styleText, textSize, children }) {
  return (
    <div
      style={{ ...styles.defaultStyle, ...style }}
    >
      {title && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Text title bold upCase value={title} style={styleText} size={textSize || 15} />

        </div>
      )}
      {children}
    </div>
  )
}

const styles = {
  defaultStyle: {
    backgroundColor: 'rgba(231, 231, 231, 0.8)',
    borderRadius: 20,
    padding: 20,
    boxShadow: '0px 4px 13px 3px rgba(100, 100, 100, 0.24)'
    /* boxShadow: '0 1px 3px 0 rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 2px 1px -1px rgba(0,0,0,.12)' */
  }
}

export default Card

/* import logo from './logo.svg'; */
import './App.css'
import React from 'react'
import { Wrapper } from './Components'
import { QueryClientProvider, QueryClient } from 'react-query'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { SentPayChecks } from './Pages/SentPayChecks'

function App () {
  const queryClient = new QueryClient()
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path='*' element={<Wrapper queryClient={queryClient} />} />
          <Route path='/sent' element={<SentPayChecks />} />
        </Routes>
      </QueryClientProvider>
    </BrowserRouter>
  )
}

export default App

const feathers = require('@feathersjs/feathers')
const rest = require('@feathersjs/rest-client')

const app = feathers()

// Connect to a different URL
const API_BASE_URL = window._env_ && window._env_.API_BASE_URL ? window._env_.API_BASE_URL : 'http://localhost:3000'

const restClient = rest(API_BASE_URL)

// Configure an AJAX library (see below) with that client
app.configure(restClient.fetch(window.fetch))

export { app as FC }

import Flex from './Flex'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { useRef, useState } from 'react'
import { FC } from '../../Services/Client'
import { Toast } from 'primereact/toast'
import { Password } from 'primereact/password'

export default function Login ({ setLogin, setIsAdmin, queryClient }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const toast = useRef(null)

  const login = async () => {
    // alert("login")
    try {
      const res = await FC.service('authentication').create({
        strategy: 'local',
        email: email,
        password: password
      })
      if (res.accessToken) {
        window.localStorage.setItem('jwt', res.accessToken)
        toast.current.show({ severity: 'success', summary: '', detail: 'Login avvenuto con successo', life: 3000 })
        if (res.user.admin) {
          setIsAdmin(true)
        }
        queryClient.invalidateQueries('employees')
        setTimeout(() => {
          setLogin(true)
        }, 1000)
      }
    } catch {
      toast.current.show({ severity: 'error', summary: 'Errore Login', detail: 'Credenziali errate', life: 3000 })
    }
  }

  return (
    <Flex>
      <Toast ref={toast} />
      <span className='p-float-label' style={{ margin: 30 }}>
        <InputText id='in' value={email} onChange={(e) => setEmail(e.target.value)} />
        <label htmlFor='in'>Email</label>
      </span>
      <span className='p-float-label'>
        <Password id='in' value={password} onChange={(e) => setPassword(e.target.value)} feedback={false} />
        <label htmlFor='in'>Password</label>
      </span>
      <Button style={{ margin: 20 }} label='Login' onClick={login} />
    </Flex>
  )
}

import React from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { FC } from '../../Services/Client'
import { Text } from './Text'
import { useForm } from 'react-hook-form'

const EmployeeForm = ({ queryClient }) => {
  const { register, setValue, formState: { errors }, handleSubmit } = useForm()
  const onSubmit = data => addEmployee(data)

  const addEmployee = async (data) => {
    try {
      await FC.service('employees').create(data, {
        headers: {
          Authorization: `${window.localStorage.getItem('jwt')}`
        }
      })
      queryClient.invalidateQueries('employees')
      setValue('nome', '')
      setValue('cognome', '')
      setValue('codiceFiscale', '')
      setValue('email', '')
    } catch (err) {
      console.log(err)
    }
  }

  return (

    <div className='card' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20, backgroundColor: 'white', borderRadius: 10, width: 250 }}>
      <Text style={{ margin: 15 }} bold size={20} value='Aggiungi dipendente' />
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column' }}>
        <span style={{ margin: 5, alignItems: 'center', textAlign: 'center' }}>
          <small className='p-error p-d-block'>{errors.nome && 'Inserire il nome'}</small><br />
          <InputText placeholder='Nome' {...register('nome', { required: true })} style={{ marginBottom: 5 }} />
        </span>
        <span className='p-float-label' style={{ margin: 5, alignItems: 'center', textAlign: 'center' }}>
          <small className='p-error p-d-block'>{errors.cognome && 'Inserire il cognome'}</small><br />
          <InputText placeholder='Cognome' {...register('cognome', { required: true })} style={{ marginBottom: 5 }} />
        </span>
        <span className='p-float-label' style={{ margin: 5, alignItems: 'center', textAlign: 'center' }}>
          <small className='p-error p-d-block'>
            {errors.codiceFiscale?.type === 'pattern' && 'Formato codice fiscale errato'}
            {errors.codiceFiscale?.type === 'required' && 'Inserire il codice fiscale'}
          </small><br />
          <InputText style={{ marginBottom: 5 }} placeholder='Codice Fiscale' {...register('codiceFiscale', { required: true, pattern: /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/i })} />
        </span>
        <span className='p-float-label' style={{ margin: 5, alignItems: 'center', textAlign: 'center' }}>
          <small className='p-error p-d-block'>
            {errors.email?.type === 'pattern' && 'Formato email errato'}
            {errors.email?.type === 'required' && 'Inserire la mail'}
          </small><br />
          <InputText style={{ marginBottom: 5 }} placeholder='Email' {...register('email', { required: true, pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/ })} />
        </span>
        <Button label='Aggiungi' type='submit' />
      </form>
    </div>
  )
}

export default EmployeeForm

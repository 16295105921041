import React from 'react'

export const Page = ({ children, style }) => (
  <div
    style={{
      marginBottom: 10,
      marginLeft: 30,
      marginRight: 30,
      marginTop: 20,
      ...style
    }}
  >
    {children}
  </div>
)

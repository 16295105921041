import React, { useState, useRef } from 'react'
import Card from './Card'
import Flex from './Flex'
import { Page } from './Page'
import { FileUpload } from 'primereact/fileupload'
import Login from './Login'
import { FC } from '../../Services/Client'
import DataTableList from './DataTableList'
import { useQuery } from 'react-query'
import { Button } from 'primereact/button'
import EmployeeForm from './EmployeeForm'
import { Text } from './Text'
import { Toast } from 'primereact/toast'
import { useMount } from 'react-use'
import { Dropdown } from 'primereact/dropdown'
import { useNavigate } from 'react-router-dom'
import { Spinner } from './Spinner'

const months = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre', 'Tredicesima']

const getMonths = () => {
  const currentYear = parseInt(new Date().getFullYear())
  return [...months.map(m => m + ' ' + (currentYear - 1)), ...months.map(m => m + ' ' + currentYear), 'Flexible Benefit', 'CU']
}

const Wrapper = ({ queryClient }) => {
  const [isSending, setIsSending] = useState(false)
  const toast = useRef(null)
  const navigate = useNavigate()
  const [selectedMonth, setSelectedMonth] = useState()
  const [loggedIn, setLoggedIn] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const getEmployees = async () => {
    try {
      if (window.localStorage.getItem('jwt')) {
        const res = await FC.service('employees').find({
          query: { $limit: 200 },
          headers: {
            Authorization: `${window.localStorage.getItem('jwt')}`
          }
        })
        return res.data
      }
    } catch (err) {
      console.log(err)
    }
  }

  const { data: employees } = useQuery('employees', getEmployees)

  const controlloJWT = async () => {
    try {
      if (window.localStorage.getItem('jwt')) {
        const res = await FC.service('authentication').create({
          strategy: 'jwt',
          accessToken: window.localStorage.getItem('jwt')
        })
        if (res.accessToken) {
          setLoggedIn(true)
          queryClient.invalidateQueries('employees')
          window.localStorage.setItem('jwt', res.accessToken)
          if (res.user.admin) {
            setIsAdmin(true)
          }
        }
      }
    } catch (err) {
      console.log(err)
      window.localStorage.removeItem('jwt')
    }
  }

  const readFile = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new window.FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const myUploader = async (e) => {
    if (e.files.length !== 1) {
      toast.current.show({ severity: 'error', summary: '', detail: 'Selezionare un solo file', life: 3000 })
      return
    }
    try {
      setIsSending(true)
      const name = e.files[0].name
      const format = e.files[0].name.split('.')[e.files[0].name.split('.').length - 1]
      const uri = await readFile(e.files[0])
      toast.current.show({ severity: 'success', summary: '', detail: 'Invio in corso, attendere...', life: 3000 })
      const res = await FC.service('paycheck').create({ uri, format, name, month: selectedMonth }, {
        headers: {
          Authorization: `${window.localStorage.getItem('jwt')}`
        }
      })
      toast.current.show({ severity: 'success', summary: '', detail: 'Busta Paga inviata a ' + res.length + ' dipendenti', life: 3000 })
      setIsSending(false)
    } catch (e) {
      console.log(e)
    }
  }

  const logout = () => {
    window.localStorage.removeItem('jwt')
    window.location.reload()
  }

  const chooseOptions = { label: 'Seleziona File', icon: 'pi pi-fw pi-plus' }
  const uploadOptions = { label: 'Invia', icon: 'pi pi-upload', className: 'p-button-success' }
  const cancelOptions = { label: 'Cancella', icon: 'pi pi-times', className: 'p-button-danger' }

  useMount(() => controlloJWT())

  return (
    <Page>
      <Toast ref={toast} />
      <Card>
        {loggedIn &&
          <>
            <Button onClick={() => navigate('/sent')} label='Visualizza Buste paga inviate' style={{ position: 'absolute', right: 150 }} />
            <Button onClick={logout} label='Logout' style={{ position: 'absolute', right: 50 }} />
          </>}
        <Flex>
          <h1>Buste Paga</h1>
          {!loggedIn
            ? <Login queryClient={queryClient} setIsAdmin={setIsAdmin} setLogin={setLoggedIn} />
            : isAdmin && (
              <>
                <Dropdown
                  value={selectedMonth} options={getMonths()} style={{ width: 200, marginBottom: 10 }}
                  onChange={({ value }) => setSelectedMonth(value)} placeholder='Seleziona Mensilità'
                />
                {isSending
                  ? <Flex fw> <Spinner /> </Flex>
                  : <FileUpload name='Buste Paga' url='./upload' customUpload uploadHandler={myUploader} chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions} />}
                <Text bold value='Gestione dipendenti' size={30} style={{ marginTop: 30 }} />
                <Flex row fw>
                  <DataTableList style={{ margin: 30 }} list={employees} queryClient={queryClient} />
                  <EmployeeForm queryClient={queryClient} />
                </Flex>
              </>)}
        </Flex>
      </Card>
    </Page>

  )
}

export default Wrapper
